<template lang="pug">
    .bootcamp-how-it-works#how-it-works
        v-row(dense)
            v-col(cols="12" md="6")
                v-img(eager :src="image" min-width="100px" :min-height="$vuetify.breakpoint.mdAndUp? '300px' : '50px'" max-width="600px" contain style="width:100%; height:auto;").mr-6.mx-auto.pa-4.pr-md-12
            v-col(cols="12" md="6")
                h2.mb-3 Focus on speaking, speaking, speaking (and grammar)
                p
                  strong You get 2 trainers for every lesson:
                ol.ml-3
                    li A <b>native English trainer</b> with TESOL certication
                    li A Russian-speaking linguistics university graduate
                p
                  strong You are joined by a 2nd IT Professional student at the same level as you:
                ul.ml-3
                    li Perfect for role-play exercises and for building confidence
                    li You learn from their errors, they learn from yours
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'BootCampFeatures',
  data() {
    return {
      image: require('@/assets/bootcamp/timing.svg'),
      textVersions: {
        en: {
        },
        ru: {
          checkmarks: [
            'Learn B1-level grammar',
            'Speak about your work with precision'
          ]
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped></style>
