<template lang="pug">
  .bootcamp-page
    .max-width.px-5.px-md-0
      MastheadNav
      MastheadBootcamp

      NextCohort.my-24(color="textDark")
      BootcampFeatures
      Goals.my-12
      HowBootCampWorks.my-6.my-md-12
      Curriculumn.my-6.my-md-12
      Pricing.my-12
      Testimonials.my-12
    .footers
      Footer
      SubFooter
</template>

<script>
import { get, call } from 'vuex-pathify'
import MastheadNav from '@/components/Static/MastheadNav'
import MastheadBootcamp from '@/components/Static/MastheadBootcamp'
import BootcampFeatures from '@/components/Static/Bootcamp/BootcampFeatures'
import Goals from '@/components/Static/Bootcamp/Goals'
import NextCohort from '@/components/Static/Bootcamp/NextCohort'
import HowBootCampWorks from '@/components/Static/Bootcamp/HowBootCampWorks'
import Curriculumn from '@/components/Static/Bootcamp/Curriculum'
import Testimonials from '@/components/Static/Bootcamp/Testimonials'
import Footer from '@/components/Static/Footer'
import SubFooter from '@/components/Static/SubFooter'
const Pricing = () => import('@/components/Static/Bootcamp/BootCampPricing')
export default {
  name: 'HomePage',
  components: {
    MastheadNav,
    MastheadBootcamp,
    BootcampFeatures,
    Goals,
    NextCohort,
    HowBootCampWorks,
    Curriculumn,
    Pricing,
    Testimonials,
    Footer,
    SubFooter
  },
  title: 'PROIT English',
  created() {
  },
  data() {
    return {
      textVersions: {
        en: {

        },
        ru: {

        },
        ko: {

        }
      }

    }
  },
  mounted() {
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  },
  methods: {
    ...call({ updateSystemLanguage: 'user/updateSystemLanguage' })
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.bootcamp-page {
  .max-width {
    max-width: 1200px;
  }

}
</style>
