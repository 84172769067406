<template lang="pug">
    .testimonials
        v-card.testimonial(v-for="itm in items", :key="itm.text" outlined).my-6.pa-5
            .text {{itm.text}}
            .d-flex.align-center
                small
                    em.author – {{itm.author}}
                v-rating(dense :value="5" readonly color="orange").ml-2

</template>

<script>
export default {
  name: 'Testimonials',
  data() {
    return {
      items: [
        {
          author: 'Anastasia',
          text:
            'I tried several teachers before I met Anna, but I was always at the same level. With Anna I quickly learned grammar correctly and started using it in my speaking easily.'
        },
        {
          author: 'Eugene',
          text:
            'I always have fun during our lessons and the time goes by so fast. But the main thing is before I was afraid to speak in English, and now it feels easy and normal for me to speak for hours without stopping.'
        }
      ]
    }
  }
}
</script>
