<template lang="pug">
    a.btn.start.white--text.text-none(:href="cleanAnchor" :class="{block: $vuetify.breakpoint.smAndDown}") {{text.buttonText}}
        v-icon(color="white").ml-2 mdi-arrow-down
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'BtnLearnMore',
  props: {
    anchor: { type: String, required: true }
  },
  data() {
    return {
      textVersions: {
        en: {
          buttonText: 'Learn More'
        },
        ru: {
          buttonText: 'Подробнее'
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    cleanAnchor() {
      if (!this.anchor) return '#'
      return this.anchor.startsWith('#') ? this.anchor : '#' + this.anchor
    },
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped>

  .btn {
    background: black;
    display: inline-block;
    padding: 0.5rem;
    font-size: 1.15rem;
    box-shadow: 3px 3px rgba(0, 0, 0, 0);
     text-align:center;
  }

  .btn:hover {
    transition: 0.3s all ease-in;
    box-shadow: 10px 10px rgba(0, 0, 0, 0.2);
  }

  .btn.block{width:100%;}

@media only screen and (min-width: 600px) {

  .btn {
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.35rem;
  }

}

</style>
