<template lang="pug">
    v-card.curriculum.grey.lighten-5( shaped).py-5
        v-row(align="center")
            v-col(cols="12" md="8").px-6
                h2 Bootcamp Curriculum (All 18 Lessons)
                p.my-6 Our course textbook is <em>English Grammar in Use (Fifth Edition)</em>
                    a(:href="link" target="_blank").ml-md-3 [Book link]
                p.my-6 This is an intensive Boot Camp.
                p.my-6 Getting from Elementary -> Pre-Intermediate (B1) within 6 weeks is definitely possible, but you must be ready to study.
                p.my-6
                    strong You are responsible for 1 ~ 3 hours of grammar review before each lesson.
            v-col(cols="12" md="4")
                a(:href="link", target="_blank")
                    v-img(eager src="@/assets/bootcamp/textbook.png" min-width="100px" :min-height="$vuetify.breakpoint.mdAndUp? '200px' : '50px'" max-width="300px" contain style="width:100%; height:auto;").mr-6.mx-auto
        v-data-table(:items="items", :headers="text.headers" disable-pagination hide-default-footer :hide-default-header="$vuetify.breakpoint.smAndDown")
            template(v-slot:item.day="{ item }")
                mark(v-if="$vuetify.breakpoint.smAndDown")
                    strong.mr-1 Day
                    | {{item.day}}
                .text-center(v-else) {{item.day}}
            template(v-slot:item.topic="{ item }")
                .text-left
                    strong {{$vuetify.breakpoint.smAndDown ? 'Topic: ' : ''}}
                    | {{item.topic}}
            template(v-slot:item.grammar="{ item }")
                .text-left
                    strong {{$vuetify.breakpoint.smAndDown ? 'Grammar Required: ' : ''}}
                    | {{item.grammar}}
            template(v-slot:item.before="{ item }")
                .text-left
                    strong {{$vuetify.breakpoint.smAndDown ? 'Prep for Class: ' : ''}}
                    | {{item.before}}

        .text-center.my-5
            v-btn(v-if="!showFullList" @click="showFullList=true" outlined color="primary").mx-auto Review entire curriculum...
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'Curriculum',
  data() {
    return {
      showFullList: false,
      link: 'https://www.cambridge.org/gb/cambridgeenglish/catalog/grammar-vocabulary-and-pronunciation/english-grammar-use-5th-edition',
      textVersions: {
        en: {
          headers: [
            {
              text: 'Day',
              value: 'day',
              align: 'center'
            },

            {
              text: 'Speaking Topic',
              value: 'topic',
              sortable: false,
              align: 'start'
            },
            {
              text: 'Grammar Required',
              value: 'grammar',
              sortable: false
            },
            {
              text: 'Prep for Class',
              value: 'before',
              sortable: false
            }
          ],
          allItems: [
            {
              day: 1,
              grammar: 'Simple Tense Review',
              topic: 'Introductions; Personal & Professional History',
              before: 'EGIU pages: 1 ~ 14'
            },
            {
              day: 2,
              grammar: 'Questions & Answers, Future Tense',
              topic: 'Personal & Professional Goals',
              before: 'EGIU pages: 18 ~ 23'
            },
            {
              day: 3,
              grammar: 'Modals',
              topic: 'Setting role responsibilities & team policies',
              before: 'EGIU pages: 26 ~ 35'
            },
            {
              day: 4,
              grammar: 'Modals',
              topic: 'Changing direction & informing the team',
              before: 'EGIU pages: 37 ~ 41'
            },
            {
              day: 5,
              grammar: 'Passive Voice & Reported Speech',
              topic: 'Handling Mistakes & Apologizing',
              before: 'EGIU pages: 42 ~ 46, pages: 47 ~ 48'
            },
            {
              day: 6,
              grammar: 'Questions & Auxillary Verbs',
              topic: 'Assigning work & checking progress',
              before: 'EGIU pages: 49 ~ 52'
            }

          ]
        }
      }
    }
  },
  created() {
    const defaultItems = []
    for (let i = 7; i < 19; i++) {
      defaultItems.push({
        day: i,
        grammar: '?',
        topic: '?',
        before: 'EGIU pages: ?? ~ ??'
      })
    }
    this.text.allItems.push(...defaultItems)
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    },
    items() {
      return this.showFullList ? this.text.allItems : this.text.allItems.slice(0, 3)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
