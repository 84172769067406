<template lang="pug">
    .next-cohort
            v-alert(dense shaped outlined :color="color")
                v-row(dense align="center")
                    v-col(cols="12" md="9")
                        .d-flex.align-start.align-md-center
                            v-icon(:large="$vuetify.breakpoint.smAndDown" :color="color").mt-2.mt-md-0.mr-2 mdi-calendar
                            .font-weight-bold {{text.alert}}
                    v-col(cols="12" md="3")
                        v-btn(:color="color" small depressed block to="/bootcamp/sign-up").white--text Sign up here
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'NextCohort',
  props: { color: { type: String, default: 'info' } },
  data() {
    return {
      textVersions: {
        en: {
          alert: 'Next cohort starts Monday, February 1, 2020'
        },
        ru: {
          alert: 'Next cohort starts Monday, February 1, 2020'
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped>
.next-cohort{
    max-width:700px;
    margin:auto;
}
</style>
