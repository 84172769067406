<template lang="pug">
    .masthead
        v-row(align="start")
            v-col(cols="12" md="5" v-if="showImage")
                v-img(eager :src="image" min-width="100px" :min-height="$vuetify.breakpoint.mdAndUp? '400px' : '50px'" max-width="800px" contain style="width:100%; height:auto;" @load="handleImageLoaded").mr-6
            v-col(cols="12" md="7")
                h1.mb-5(v-html="text.title")
                .content
                  span.subtitle(v-html="text.subtitle")
                BtnLearnMore.mt-6(anchor="features")

</template>

<script>
import { get } from 'vuex-pathify'
import { annotate } from 'rough-notation'
import BtnLearnMore from '@/components/Static/BtnLearnMore'
export default {
  name: 'Masthead',
  components: { BtnLearnMore },
  watch: {
    lang() {
      setTimeout(() => {
        this.doAnnotation()
      }, 2000)
    }
  },
  data() {
    return {
      image: require('@/assets/bootcamp/videocall.svg'),
      textVersions: {
        en: {
          title:
            'In the IT <span class="anno anno1 remove">sphere</span><span class="anno anno2 add d-none">industry</span>, good English matters.',
          subtitle:
            'Do you speak Russian? Do you need to level up to Pre‑Intermediate (B1) English quickly?'
        },
        ru: {
          title:
            'Возьмите в штат редактора-носителя английского языка, без ежемесячной оплаты.',
          subtitle:
            "PROIT English – это сервис, осуществляющий <span class='anno anno1'>редактирование текста вручную</span> для компаний, ориентированных на международный рынок."
        }
      },

      ready: false,
      didAnnotation: false
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      // for small devices, we don't ever load the masthead image, so instead on mounted we'll start annotations
      // for large devices we skip this, and run the same code after image loaded.
      document.fonts.ready.then(() => {
        setTimeout(() => {
          this.doAnnotation()
        }, 3000)
      })
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    showImage() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  },
  methods: {
    handleImageLoaded() {
      document.fonts.ready.then(() => {
        setTimeout(() => {
          this.doAnnotation()
        }, 2500)
      })
    },
    doAnnotation() {
      const e1 = document.querySelector('.anno1')
      e1.classList.add('error--text')
      const e2 = document.querySelector('.anno2.add')
      e2.classList.remove('d-none')

      setTimeout(() => {
        if (e1) {
          const annotation = annotate(e1, {
            type: 'crossed-off',
            multiline: true,
            color: this.$vuetify.theme.themes.light.error
          })
          annotation.show()
        }

        e2.classList.add('success--text')

        if (e2) {
          const annotation = annotate(e2, {
            type: 'box',
            multiline: true,
            color: this.$vuetify.theme.themes.light.success
          })
          annotation.show()
        }
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
h1 {
  font-weight: bolder;
  line-height: 1.35;
  font-size: 1.8rem;
  // color: black;
}

h2 {
  font-size: 2rem;
}

span.subtitle {
  color: rgb(151, 143, 143);
  font-size:1.35rem;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 3rem;
  }

  span.subtitle {
  font-size:1.35rem;
}
}
</style>
