var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"curriculum grey lighten-5 py-5",attrs:{"shaped":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"px-6",attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("Bootcamp Curriculum (All 18 Lessons)")]),_c('p',{staticClass:"my-6"},[_vm._v("Our course textbook is "),_c('em',[_vm._v("English Grammar in Use (Fifth Edition)")]),_c('a',{staticClass:"ml-md-3",attrs:{"href":_vm.link,"target":"_blank"}},[_vm._v("[Book link]")])]),_c('p',{staticClass:"my-6"},[_vm._v("This is an intensive Boot Camp.")]),_c('p',{staticClass:"my-6"},[_vm._v("Getting from Elementary -> Pre-Intermediate (B1) within 6 weeks is definitely possible, but you must be ready to study.")]),_c('p',{staticClass:"my-6"},[_c('strong',[_vm._v("You are responsible for 1 ~ 3 hours of grammar review before each lesson.")])])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('a',{attrs:{"href":_vm.link,"target":"_blank"}},[_c('v-img',{staticClass:"mr-6 mx-auto",staticStyle:{"width":"100%","height":"auto"},attrs:{"eager":"","src":require("@/assets/bootcamp/textbook.png"),"min-width":"100px","min-height":_vm.$vuetify.breakpoint.mdAndUp? '200px' : '50px',"max-width":"300px","contain":""}})],1)])],1),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.text.headers,"disable-pagination":"","hide-default-footer":"","hide-default-header":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"item.day",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('mark',[_c('strong',{staticClass:"mr-1"},[_vm._v("Day")]),_vm._v(_vm._s(item.day))]):_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.day))])]}},{key:"item.topic",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Topic: ' : ''))]),_vm._v(_vm._s(item.topic))])]}},{key:"item.grammar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Grammar Required: ' : ''))]),_vm._v(_vm._s(item.grammar))])]}},{key:"item.before",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('strong',[_vm._v(_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Prep for Class: ' : ''))]),_vm._v(_vm._s(item.before))])]}}])}),_c('div',{staticClass:"text-center my-5"},[(!_vm.showFullList)?_c('v-btn',{staticClass:"mx-auto",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showFullList=true}}},[_vm._v("Review entire curriculum...")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }