<template lang="pug">
    .bootcamp-features#features
        v-row(dense)
            v-col(cols="12" md="8")
                h2.mb-3 A 6-week Bootcamp for Russian-speaking IT Workers who are ready to STUDY.
                p We meet <b>3 times per week, for 90 minutes each time</b>, to raise your English level to Pre-Intermediate.
                .checkmarks(v-for="itm in text.checkmarks", :key="itm")
                    v-row()
                        v-col(cols="1")
                            v-icon mdi-check
                        v-col(cols="11" v-html="itm")
            v-col(cols="12" md="4")
                v-img(eager :src="image" min-width="100px" :min-height="$vuetify.breakpoint.mdAndUp? '300px' : '50px'" max-width="600px" contain style="width:100%; height:auto;").mr-6.mx-auto
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'BootCampFeatures',
  data() {
    return {
      image: require('@/assets/bootcamp/certificate.svg'),
      textVersions: {
        en: {
          checkmarks: [
            'Learn B1-level <b>grammar fully</b>',
            'Learn <b>key patterns</b> that are common in IT conversations',
            'Speak about your work with <b>precision</b>',
            'Sound more <b>confident and professional</b>',
            'An intensive <b>6 week</b> program'
          ]
        },
        ru: {
          checkmarks: [
            'Learn B1-level grammar',
            'Speak about your work with precision'
          ]
        }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped></style>
